/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Wrapper from '../components/Wrapper';

const TermsAndConditionsPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section title="Privacy policy">
      <Wrapper>
        <p>
          This privacy policy ("Policy") describes how Kongrads, Inc.
          ("Kongrads, Inc.", "we", "us" or
          "our") collects, protects and uses the personally
          identifiable information ("Personal Information") you
          ("User", "you" or "your") may provide on
          the <Link to="/">kongrads.com</Link> website and any of its products or services (collectively,
          "Website" or "Services").
        </p>
        <p>
          It also describes the choices available to you regarding our use of
          your Personal Information and how you can access and update this
          information. This Policy does not apply to the practices of companies
          that we do not own or control, or to individuals that we do not employ
          or manage.
        </p>
        <h2>Automatic collection of information</h2>
        <p>
          Our top priority is customer data security and, as such, we exercise
          the no logs policy. We process only minimal user data, only as much as
          it is absolutely necessary to maintain the Website or Services.
          Information collected automatically is used only to identify potential
          cases of abuse and establish statistical information regarding Website
          usage. This statistical information is not otherwise aggregated in
          such a way that would identify any particular user of the system.
        </p>
        <h2>Collection of personal information</h2>
        <p>
          You can visit the Website without telling us who you are or revealing
          any information by which someone could identify you as a specific,
          identifiable individual. If, however, you wish to use some of the
          Website's features, you will be asked to provide certain Personal
          Information (for example, your name and e-mail address). We receive
          and store any information you knowingly provide to us when you create
          an account, make a purchase, or fill any online forms on the Website.
          When required, this information may include the following:
        </p>
        <ul>
          <li>Personal details such as name, country of residence, etc.</li>
          <li>Contact information such as email address, address, etc.</li>
          <li>
            Account details such as user name, unique user ID, password, etc.
          </li>
          <li>
            Payment information such as credit card details, bank details, etc.
          </li>
          <li>
            Information about other individuals such as your family members,
            friends, etc.
          </li>
        </ul>
        <p>
          You can choose not to provide us with your Personal Information, but
          then you may not be able to take advantage of some of the Website's
          features. Users who are uncertain about what information is mandatory
          are welcome to contact us.
        </p>
        <h2>Managing personal information</h2>
        <p>
          You are able to delete certain Personal Information we have about you.
          The Personal Information you can delete may change as the Website or
          Services change. When you delete Personal Information, however, we may
          maintain a copy of the unrevised Personal Information in our records
          for the duration necessary to comply with our obligations to our
          affiliates and partners, and for the purposes described below. If you
          would like to delete your Personal Information or permanently delete
          your account, you can do so on the settings page of your account on
          the Website.
        </p>
        <h2>Storing personal information</h2>
        <p>
          We will retain and use your Personal Information for the period
          necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements unless a longer retention period is required or
          permitted by law. We may use any aggregated data derived from or
          incorporating your Personal Information after you update or delete it,
          but not in a manner that would identify you personally. Once the
          retention period expires, Personal Information shall be deleted.
          Therefore, the right to access, the right to erasure, the right to
          rectification and the right to data portability cannot be enforced
          after the expiration of the retention period.
        </p>
        <h2>Use and processing of collected information</h2>
        <p>
          In order to make our Website and Services available to you, or to meet
          a legal obligation, we need to collect and use certain Personal
          Information. If you do not provide the information that we request, we
          may not be able to provide you with the requested products or
          services. Some of the information we collect is directly from you via
          our Website. However, we may also collect Personal Information about
          you from other sources such as social media, emails, etc. Any of the
          information we collect from you may be used for the following
          purposes:
        </p>
        <ul>
          <li>Create and manage user accounts</li>
          <li>Fulfill and manage orders</li>
          <li>Deliver products or services</li>
          <li>Improve products and services</li>
          <li>Send administrative information</li>
          <li>Send marketing and promotional communications</li>
          <li>Respond to inquiries and offer support</li>
          <li>Request user feedback</li>
          <li>Improve user experience</li>
          <li>Post customer testimonials</li>
          <li>Enforce terms and conditions and policies</li>
          <li>Protect from abuse and malicious users</li>
          <li>Respond to legal requests and prevent harm</li>
          <li>Run and operate our Website and Services</li>
        </ul>
        <p>
          Processing your Personal Information depends on how you interact with
          our Website, where you are located in the world and if one of the
          following applies: (i) You have given your consent for one or more
          specific purposes. This, however, does not apply, whenever the
          processing of Personal Information is subject to California Consumer
          Privacy Act or European data protection law; (ii) Provision of
          information is necessary for the performance of an agreement with you
          and/or for any pre-contractual obligations thereof; (iii) Processing
          is necessary for compliance with a legal obligation to which you are
          subject; (iv) Processing is related to a task that is carried out in
          the public interest or in the exercise of official authority vested in
          us; (v) Processing is necessary for the purposes of the legitimate
          interests pursued by us or by a third party.
        </p>
        <p>
          {" "}
          Note that under some legislations we may be allowed to process
          information until you object to such processing (by opting out),
          without having to rely on consent or any other of the following legal
          bases below. In any case, we will be happy to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Information is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </p>
        <h2>The rights of users</h2>
        <p>
          You may exercise certain rights regarding your information processed
          by us. In particular, you have the right to do the following: (i) you
          have the right to withdraw consent where you have previously given
          your consent to the processing of your information; (ii) you have the
          right to object to the processing of your information if the
          processing is carried out on a legal basis other than consent; (iii)
          you have the right to learn if information is being processed by us,
          obtain disclosure regarding certain aspects of the processing and
          obtain a copy of the information undergoing processing; (iv) you have
          the right to verify the accuracy of your information and ask for it to
          be updated or corrected; (v) you have the right, under certain
          circumstances, to restrict the processing of your information, in
          which case, we will not process your information for any purpose other
          than storing it; (vi) you have the right, under certain circumstances,
          to obtain the erasure of your Personal Information from us; (vii) you
          have the right to receive your information in a structured, commonly
          used and machine readable format and, if technically feasible, to have
          it transmitted to another controller without any hindrance. This
          provision is applicable provided that your information is processed by
          automated means and that the processing is based on your consent, on a
          contract which you are part of or on pre-contractual obligations
          thereof.
        </p>
        <h2>The right to object to processing</h2>
        <p>
          Where Personal Information is processed for the public interest, in
          the exercise of an official authority vested in us or for the purposes
          of the legitimate interests pursued by us, you may object to such
          processing by providing a ground related to your particular situation
          to justify the objection. You must know that, however, should your
          Personal Information be processed for direct marketing purposes, you
          can object to that processing at any time without providing any
          justification. To learn, whether we are processing Personal
          Information for direct marketing purposes, you may refer to the
          relevant sections of this document.
        </p>
        <h2>How to exercise these rights</h2>
        <p>
          Any requests to exercise User rights can be directed to Kongrads, Inc.
          through the contact details provided in this document. These requests
          can be exercised free of charge and will be addressed by Kongrads,
          Inc. as early as possible.
        </p>
        <h2>California privacy rights</h2>
        <p>
          In addition to the rights as explained in this Privacy Policy,
          California residents who provide Personal Information (as defined in
          the statute) to obtain products or services for personal, family, or
          household use are entitled to request and obtain from us, once a
          calendar year, information about the Personal Information we shared,
          if any, with other businesses for marketing uses. If applicable, this
          information would include the categories of Personal Information and
          the names and addresses of those businesses with which we shared such
          personal information for the immediately prior calendar year (e.g.,
          requests made in the current year will receive information about the
          prior year). To obtain this information please contact us.
        </p>
        <h2>Billing and payments</h2>
        <p>
          We use third-party payment processors to assist us in processing your
          payment information securely. Such third-party processors' use of your
          Personal Information is governed by their respective privacy policies
          which may or may not contain privacy protections as protective as this
          Privacy Policy. We suggest that you review their respective privacy
          policies.
        </p>
        <h2>Privacy of children</h2>
        <p>
          We do not knowingly collect any Personal Information from children
          under the age of 13. If you are under the age of 13, please do not
          submit any Personal Information through our Website or Service. We
          encourage parents and legal guardians to monitor their children's
          Internet usage and to help enforce this Policy by instructing their
          children never to provide Personal Information through our Website or
          Service without their permission.
        </p>
        <p>
          If you have reason to believe that a child under the age of 13 has
          provided Personal Information to us through our Website or Service,
          please contact us. You must also be old enough to consent to the
          processing of your Personal Information in your country (in some
          countries we may allow your parent or guardian to do so on your
          behalf).
        </p>
        <h2>Do Not Track signals</h2>
        <p>
          Some browsers incorporate a Do Not Track feature that signals to
          websites you visit that you do not want to have your online activity
          tracked. Tracking is not the same as using or collecting information
          in connection with a website. For these purposes, tracking refers to
          collecting personally identifiable information from consumers who use
          or visit a website or online service as they move across different
          websites over time. How browsers communicate the Do Not Track signal
          is not yet uniform. As a result, this Website is not yet set up to
          interpret or respond to Do Not Track signals communicated by your
          browser. Even so, as described in more detail throughout this Policy,
          we limit our use and collection of your personal information.
        </p>
        <h2>Links to other websites</h2>
        <p>
          Our Website contains links to other websites that are not owned or
          controlled by us. Please be aware that we are not responsible for the
          privacy practices of such other websites or third-parties. We
          encourage you to be aware when you leave our Website and to read the
          privacy statements of each and every website that may collect Personal
          Information.
        </p>
        <h2>Information security</h2>
        <p>
          We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or
          disclosure. We maintain reasonable administrative, technical, and
          physical safeguards in an effort to protect against unauthorized
          access, use, modification, and disclosure of Personal Information in
          its control and custody. However, no data transmission over the
          Internet or wireless network can be guaranteed. Therefore, while we
          strive to protect your Personal Information, you acknowledge that (i)
          there are security and privacy limitations of the Internet which are
          beyond our control; (ii) the security, integrity, and privacy of any
          and all information and data exchanged between you and our Website
          cannot be guaranteed; and (iii) any such information and data may be
          viewed or tampered with in transit by a third-party, despite best
          efforts.
        </p>
        <h2>Data breach</h2>
        <p>
          In the event we become aware that the security of the Website has been
          compromised or users Personal Information has been disclosed to
          unrelated third parties as a result of external activity, including,
          but not limited to, security attacks or fraud, we reserve the right to
          take reasonably appropriate measures, including, but not limited to,
          investigation and reporting, as well as notification to and
          cooperation with law enforcement authorities. In the event of a data
          breach, we will make reasonable efforts to notify affected individuals
          if we believe that there is a reasonable risk of harm to the user as a
          result of the breach or if notice is otherwise required by law. When
          we do, we will send you an email.
        </p>
        <h2>Legal disclosure</h2>
        <p>
          We will disclose any information we collect, use or receive if
          required or permitted by law, such as to comply with a subpoena, or
          similar legal process, and when we believe in good faith that
          disclosure is necessary to protect our rights, protect your safety or
          the safety of others, investigate fraud, or respond to a government
          request. In the event we go through a business transition, such as a
          merger or acquisition by another company, or sale of all or a portion
          of its assets, your user account, and Personal Information will likely
          be among the assets transferred.
        </p>
        <h2>Changes and amendments</h2>
        <p>
          We may update this Privacy Policy from time to time in our discretion
          and will notify you of any material changes to the way in which we
          treat Personal Information. When changes are made, we will post a
          notification on the main page of our Website. We may also provide
          notice to you in other ways in our discretion, such as through contact
          information you have provided. Any updated version of this Privacy
          Policy will be effective immediately upon the posting of the revised
          Privacy Policy unless otherwise specified. Your continued use of the
          Website or Services after the effective date of the revised Privacy
          Policy (or such other act specified at that time) will constitute your
          consent to those changes. However, we will not, without your consent,
          use your Personal Data in a manner materially different than what was
          stated at the time your Personal Data was collected.
        </p>
        <h2>Acceptance of this policy</h2>
        <p>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By using the Website or its Services you agree
          to be bound by this Policy. If you do not agree to abide by the terms
          of this Policy, you are not authorized to use or access the Website
          and its Services.
        </p>
        <h2>Contacting us</h2>
        <p>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to individual
          rights and your Personal Information, you may send an email to
          &#115;up&#112;o&#114;t&#64;ko&#110;&#103;ra&#100;s&#46;c&#111;&#109;
        </p>
        <p>This document was last updated on April 28, 2020</p>
      </Wrapper>
    </Section>
  </Layout>
)

export default TermsAndConditionsPage
